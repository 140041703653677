import React from 'react'
import Navbar from '../Navbar'

class Header extends React.Component {
  constructor() {
    super()
    this.toggleClass = this.toggleClass.bind(this)
    this.state = {
      active: false,
    }
  }

  toggleClass() {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  render() {
    return <Navbar />
  }
}

export default Header
