import React from 'react';

const ReceiptLogin = props => (
  <div className="modal is-active">
    <div className="modal-background" onClick={props.closeReceiptLogin} />
    <div pose={props.isOpen ? 'visible' : 'hidden'} className="modal-content">
      <div className="box">
        <h1 className="title">Ingresar al sistema de facturación</h1>
        <form action="https://cfd.sicofi.com.mx/Sicofi/Main" method="POST">
          <div className="field">
            <label htmlFor="Login" className="label">
              Usuario
            </label>
            <div className="control">
              <input
                type="text"
                className="input"
                name="Login"
                placeholder="Usuario"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope" />
              </span>
            </div>
          </div>
          <div className="field">
            <label htmlFor="Password" className="label">
              Contraseña
            </label>
            <div className="control">
              <input
                type="password"
                className="input"
                name="Password"
                placeholder="Contrasena"
              />
            </div>
            <span className="icon is-small is-left">
              <i className="fas fa-lock" />
            </span>
          </div>
          <div className="field">
            <div className="control">
              <button type="submit" className="button is-grayblue">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <button
      className="modal-close is-large"
      aria-label="close"
      onClick={props.closeReceiptLogin}
    />
  </div>
)

export default ReceiptLogin
