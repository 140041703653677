import React from 'react'
import Link from 'gatsby-link'

// static elements
import logo from '../../static/logo.svg'
import ReceiptLogin from '../Receipt/ReceiptLogin'

class Navbar extends React.Component {
  constructor() {
    super()
    this.state = {
      activeReceiptLogin: false,
      activeMenu: false,
      openModal: false,
    }
  }

  renderReceiptLogin() {
    if (this.state.activeReceiptLogin) {
      return (
        <ReceiptLogin
          isActive={this.state.activeReceiptLogin}
          closeReceiptLogin={this.closeReceiptLogin}
          isOpen={this.state.openModal}
        />
      )
    }
  }

  toggleMenu = () => {
    const currentValue = this.state.activeMenu
    this.setState({
      activeMenu: !currentValue,
    })
  }

  openReceiptLogin = () => {
    this.setState({
      activeReceiptLogin: true,
    })
    setTimeout(() => {
      this.setState({ openModal: true })
    }, 20)
  }

  closeReceiptLogin = () => {
    this.setState({ openModal: false })
    setTimeout(() => {
      this.setState({
        activeReceiptLogin: false,
      })
    }, 150)
  }

  render() {
    return (
      <nav
        className="navbar is-primary is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img
              src={logo}
              alt="Soluciones en Tecnología e Información del Noroeste"
              className="navbar-brand__logo"
            />
          </Link>
          <a
            role="button"
            className={`navbar-burger ${
              this.state.activeMenu ? 'is-active' : ''
            }`}
            aria-label="menu"
            aria-expanded="false"
            onClick={this.toggleMenu}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          className={`navbar-menu ${this.state.activeMenu ? 'is-active' : ''}`}
        >
          <div className="navbar-start">
            <Link to="/facturacion" className="navbar-item">
              Facturación Electrónica
            </Link>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="field">
                <div className="control">
                  <a
                    className="button is-primary is-inverted"
                    onClick={this.openReceiptLogin}
                  >
                    Ingreso al Sistema de Facturación
                  </a>
                </div>
              </div>
            </div>
            {this.renderReceiptLogin()}
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
