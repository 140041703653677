import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from './Shared/Header'
import Footer from './Shared/Footer'
// import './index.css'
import './index.scss'

const Layout = ({ children }) => (
  <div>
    <Helmet
      title="STIN | Soluciones en Tecnología e Información del Noroeste"
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    />
    <Header />
    <div className="main-wrapper">
      <div className="main">{children}</div>
    </div>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
