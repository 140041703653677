import React from 'react'
import { Phone, Home, Mail } from 'react-feather'

export default () => {
  return (
    <section className="footer">
      <div className="footer--content">
        <div className="footer--copyright">
          Soluciones en Tecnología e Información del Noroeste{' '}
          {new Date().getFullYear()}
        </div>
        <div className="footer--contact">
          <Mail /> contacto@stin.com.mx
          <br />
          <Phone /> 662-2104934
          <br />
          <Home /> Americas 144, San Benito, Hermosillo México
        </div>
      </div>
    </section>
  )
}
